.AnmeldeForm {
  padding-left: 6px;
  padding-right: 10px;
  margin-left: 10px;
  margin-right: 10px;
  max-width: 700px;

  .inputContainer {

    display: flex;
    align-items: center;
    margin: 0px;
    padding: 0px !important;
    flex-direction: column;
    &.error {

    }

    .errorText {
      display: flex;
      color: #fff;
      margin: 4px;
    }
  }

  h4 {
    margin-bottom: 4px;
  }

  .smallInfo {
    font-size: 10px;
  }

  .warning {
    border-radius: 10px;
    border-width: 1px;
    padding: 5px;
    padding-left: 14px;
    padding-right: 14px;
    background: #b45b01;
    color: #ffffff;
    margin-bottom: 11px;
  }

  .error {
    border-radius: 10px;
    border-width: 1px;
    padding: 5px;
    padding-left: 14px;
    padding-right: 14px;
    background: #b40101;
    color: #ffffff;
    margin-bottom: 11px;
  }

  .anmeldeButton {
    border-radius: 10px;
    border-width: 1px;
    padding: 5px;
    padding-left: 14px;
    padding-right: 14px;
    background: #1a3954;
    color: #ffffff;
    cursor: pointer;

    &:hover {
      background: #2f6492;
    }
  }

  /* The container */
  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 17px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default radio button */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #555;
    border-radius: 50%;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the radio button is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #2196F3;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }

  .layoutTable {

    width: 100%;

    th {
      text-align: left;
    }

    .name {
      padding-top: 8px;
      vertical-align: top;

    }

    .value {
    }
  }

  input, select {
    display: flex;
    width: 100%;
    height: 30px;
    border-radius: 8px;
    border-width: 1px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 2px;
    padding-bottom: 2px;

  }

  .btn {
    border-radius: 10px;
    border-width: 1px;
    padding: 5px;
    padding-left: 14px;
    padding-right: 14px;
  }

  .familyTd {
    font-size: 18px;
    padding-top: 10px;
    padding-bottom: 6px;

    .flex {
      display: flex;
      flex-direction: row;
    }
  }

  [type="checkbox"] {
    position: relative;
    left: 15px;
    top: -4px;
    z-index: 0;
    -webkit-appearance: none;
  }

  [type="checkbox"] + label {
    position: absolute;
  }

  [type="checkbox"] + label::before {
    width: 15px;
    height: 15px;
    border-radius: 5px;
    border: 2px solid #8cad2d;
    background-color: #fff;
    display: block;
    content: "";
    float: left;
    margin-right: 10px;
    z-index: 5;
    position: relative;
  }

  [type="checkbox"]:checked + label::before {
    box-shadow: inset 0px 0px 0px 3px #fff;
    background-color: #8cad2d;
  }

  th {
    font-weight: bold;
  }

  .sumrow {
    td {
      font-weight: bold;
    }
  }

  .priceCol {
    text-align: right;
  }
  .priceColInfo{
    font-size: 10px;
    font-weight: normal;
  }

  .noPadding {
    padding: 0px;
  }
}

.gutscheinActive{
  margin-top:5px;
  color:#2f6492;
}
.sumTable {
  td, th {
    border: 1px solid #555555;
    padding: 4px;
  }

  td.noBorder {
    border: none;
  }
}

.familyHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .del {

  }
}

.abtSelect {
  .selectedAbt {
    padding: 10px;
    max-width: 480px;
  }

  .abtEntry {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;

    .abtName {

    }

    .abtPrice {
      display: flex;
      flex-direction: row-reverse;
      text-align: right;

    }
  }

}
